<!-- eslint-disable vue/multi-word-component-names -->
<script setup>
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { gsap } from "gsap";
const { locale } = useI18n();
const route = useRoute();
const localePath = useLocalePath();
const switchLocalePath = useSwitchLocalePath();

// watch works directly on a ref
// watch(locale, (val) => {
//   const loadingElement = document.querySelector(".full-screen-loader");
//   if (loadingElement) {
//     loadingElement.style = "display: fixed;";
//     if (sessionStorage) {
//       sessionStorage.setItem("loading", "false");
//     }
//   }
// });

const navigationLeft = [
  { name: "membership", current: false },
  { name: "spaces", current: false },
  { name: "projects", current: false },
];

const navigationRight = [
  { name: "about", current: false },
  { name: "events", current: false },
  { name: "articles", current: false },
];

const width = ref(null);

const props = defineProps({
  isDark: {
    type: Boolean,
    default: false,
  },
  isWhite: {
    type: Boolean,
    default: false,
  },
});

const lastScrollY = ref(0);
const xTo = ref(0);
const yTo = ref(0);
const isNavHidden = ref(false);
const nav = ref(null);

const updateWidth = () => {
  width.value =
    document.body.clientWidth ||
    window.innerWidth ||
    document.documentElement.clientWidth;
  if (
    (width.value > 1024 && xTo?.value && yTo?.value) ||
    (matchMedia("(pointer:fine)").matches && xTo?.value && yTo?.value)
  ) {
    gsap.set("#cursor", { xPercent: -50, yPercent: -50 });

    xTo.value = gsap.quickTo("#cursor", "x", {
      duration: 0.1,
      ease: "power3",
    });
    yTo.value = gsap.quickTo("#cursor", "y", {
      duration: 0.1,
      ease: "power3",
    });
  }
};

onMounted(() => {
  // Ensure window is defined
  if (typeof window !== "undefined") {
    lastScrollY.value = window.scrollY;
    window.addEventListener("scroll", handleScroll);
  }
  width.value =
    document.body.clientWidth ||
    window.innerWidth ||
    document.documentElement.clientWidth;
  window.addEventListener("resize", updateWidth);
});

onUnmounted(() => {
  if (typeof window !== "undefined") {
    window.removeEventListener("scroll", handleScroll);
    window.removeEventListener("resize", updateWidth);
  }
});

function handleScroll() {
  const currentScrollY = window.scrollY;

  const isMenuOpen = nav.value.$el.outerHTML
    .toString()
    .includes('data-headlessui-state="open');

  if (
    !isMenuOpen &&
    currentScrollY > lastScrollY.value &&
    currentScrollY > 50
  ) {
    // User is scrolling down
    isNavHidden.value = true;
  } else {
    // User is scrolling up
    isNavHidden.value = false;
  }

  lastScrollY.value = currentScrollY;
}
const { isDark, isWhite } = props;
const getItemClass = (item) => {
  if (isDark) {
    return [
      localePath(item.name) === route.path
        ? "border-b border-current rounded-none  text-black"
        : "text-black hover:text-black",
      "px-0 lg:px-0 xl:px-0 py-2 pb-0  rounded-md text-sm font-light hover:font-bold navbar-links",
    ];
  }
  return [
    localePath(item.name) === route.path
      ? "border-b text-white"
      : "text-white hover:text-gray",
    "px-0 lg:px-0 xl:px-0 py-2 pb-0  rounded-md text-sm font-normal hover:font-bold navbar-links",
  ];
};
</script>

<template>
  <Disclosure
    ref="nav"
    v-slot="{ open, close }"
    as="nav"
    :class="
      isNavHidden
        ? ' navbar-hidden lg:block'
        : 'navbar transparent z-50 w-full fixed lg:relative'
    "
  >
    <div
      v-if="!isDark"
      class="w-full h-40 relative sm:block"
      :class="isDark ? '' : 'navbar-shadow'"
    ></div>
    <div v-else class="navbar-shadow w-full h-40 relative lg:hidden"></div>
    <div
      class="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 py-4"
      :class="isDark ? '' : ''"
    >
      <div class="relative flex h-16 items-center justify-center">
        <div class="absolute inset-y-0 right-0 flex items-center lg:hidden">
          <!-- Mobile menu button-->
          <DisclosureButton
            class="z-50 !bg-transparent inline-flex items-center justify-center rounded-md p-2 text-gray-400"
          >
            <span class="sr-only">{{ $t("open-main-menu") }}</span>
            <div class="mobile-btn">{{ open ? $t("close") : "menu" }}</div>
          </DisclosureButton>
        </div>
        <div class="flex items-center justify-center sm:items-stretch">
          <div
            class="hidden lg:block"
            :class="locale === 'cs' ? 'w-[215px]' : 'w-[235px]'"
          >
            <div class="flex gap-8">
              <NuxtLinkLocale
                v-for="item in navigationLeft"
                :key="item.name"
                :to="item.name"
                :class="getItemClass(item)"
                :data-text="$t(item.name)"
                :aria-current="item.current ? 'page' : undefined"
              >
                {{ $t(item.name) }}
              </NuxtLinkLocale>
            </div>
          </div>
          <div class="flex items-center mt-3 px-12 z-40 cursor-pointer">
            <NuxtLink
              v-if="!isWhite && route.path !== '/contact'"
              :to="localePath('/')"
            >
              <img
                class="block h-12 mt-4 w-auto z-50 relative lg:hidden"
                src="/img/logo-opero.svg"
                alt="Your Company"
              />
              <img
                class="hidden h-8 w-auto lg:block"
                src="/img/logo-opero.svg"
                alt="Your Company"
              />
            </NuxtLink>
            <NuxtLink v-else :to="localePath('/')">
              <img
                class="block h-12 mt-4 w-auto z-50 relative lg:hidden"
                src="../assets/opero-logo-white.svg"
                alt="Your Company"
              />
              <img
                class="hidden h-8 w-auto lg:block"
                src="../assets/opero-logo-white.svg"
                alt="Your Company"
              />
            </NuxtLink>
          </div>
          <div
            class="hidden lg:block"
            :class="locale === 'cs' ? 'w-[215px]' : 'w-[235px]'"
          >
            <div class="flex gap-8">
              <NuxtLinkLocale
                v-for="item in navigationRight"
                :key="item.name"
                :to="item.name"
                :class="getItemClass(item)"
                :data-text="$t(item.name)"
                :aria-current="item.current ? 'page' : undefined"
              >
                {{ $t(item.name) }}
              </NuxtLinkLocale>
            </div>
          </div>
        </div>
      </div>
      <div class="hidden absolute right-1 top-0 p-6 mr-4 lg:flex items-center">
        <NuxtLinkLocale :to="{ name: 'contact', query: { from: 'menu' } }">
          <button
            class="bg-transparent border rounded-full px-4 py-1 mr-2 font-normal nav-btns !normal-case"
            :class="{
              'text-black': isDark,
              'border-black': isDark,
              'text-white': !isDark,
              'border-white': !isDark,
            }"
          >
            {{ $t("contact") }}
          </button>
        </NuxtLinkLocale>
        <a
          href="https://membersonly.opero.cz/login"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button
            class="bg-transparent border rounded-full flex justify-center items-center font-normal nav-btns mr-2 w-8 h-8"
            :class="{
              'text-black': isDark,
              'border-black': isDark,
              'text-white': !isDark,
              'border-white': !isDark,
              'login-btn-icon': isDark,
            }"
          >
            <img
              :src="isDark ? '/img/user-icon-dark.svg' : '/img/user-icon.png'"
              class="w-4.5 lg:w-3 login-icon"
            />
          </button>
        </a>
        <div>
          <div>
            <!-- Button to switch to EN when the current locale is CS -->
            <nuxt-link
              v-if="locale === 'cs'"
              class="bg-transparent rounded-full px-4 py-2 font-normal nav-btns"
              :class="{ 'text-black': isDark, 'text-white': !isDark }"
              :to="switchLocalePath('en')"
            >
              EN
            </nuxt-link>
            <!-- Button to switch to CS when the current locale is EN -->
            <nuxt-link
              v-if="locale === 'en'"
              class="bg-transparent rounded-full px-4 py-2 font-normal nav-btns"
              :class="{ 'text-black': isDark, 'text-white': !isDark }"
              :to="switchLocalePath('cs')"
            >
              CZ
            </nuxt-link>
          </div>
        </div>
      </div>
    </div>

    <DisclosurePanel>
      <div
        class="space-y-1 flex flex-col px-2 pt-32 pb-3 bg-[#1a1a1a] z-40 relative mobile-menu text-center top-0 cursor-auto"
      >
        <DisclosureButton
          :class="[
            'text-gray-300 hover:bg-gray-700 hover:text-white',
            'block px-3 py-1 rounded-md text-base font-medium !text-[26px] leading-9 !normal-case',
          ]"
        >
          <NuxtLink :to="localePath({ name: 'index' })" @click="close"
            >Home</NuxtLink
          >
        </DisclosureButton>
        <DisclosureButton
          v-for="item in navigationLeft"
          :key="item.name"
          :class="[
            item.current
              ? 'bg-gray-900 text-white'
              : 'text-gray-300 hover:bg-gray-700 hover:text-white',
            'block px-3 py-1 rounded-md text-base font-medium !text-[26px] leading-9 !normal-case',
          ]"
          :aria-current="item.current ? 'page' : undefined"
          ><NuxtLink :to="localePath(item.name)" @click="close">{{
            $t(item.name)
          }}</NuxtLink>
        </DisclosureButton>
        <DisclosureButton
          v-for="item in navigationRight"
          :key="item.name"
          :class="[
            item.current
              ? 'bg-gray-900 text-white'
              : 'text-gray-300 hover:bg-gray-700 hover:text-white',
            'block px-3 py-1 rounded-md font-medium !text-[26px] leading-9 !normal-case',
          ]"
          :aria-current="item.current ? 'page' : undefined"
          ><NuxtLink :to="localePath(item.name)" @click="close">{{
            $t(item.name)
          }}</NuxtLink>
        </DisclosureButton>
        <div class="">
          <div>
            <a
              href="https://membersonly.opero.cz/login"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button
                class="bg-transparent border rounded-full mt-4 mb-1 px-4 py-1.5 !font-normal text-base whitespace-nowrap !normal-case"
                :class="{
                  'text-black': false,
                  'border-black': false,
                  'text-white': true,
                  'border-white': true,
                }"
              >
                {{ $t("entryForMembers") }}
              </button>
            </a>
          </div>
          <div>
            <NuxtLinkLocale to="contact" @click="close">
              <button
                class="bg-transparent border rounded-full w-24 mt-2 mb-8 px-4 py-1.5 !font-normal text-base !normal-case"
                :class="{
                  'text-black': false,
                  'border-black': false,
                  'text-white': true,
                  'border-white': true,
                }"
              >
                {{ $t("contact") }}
              </button>
            </NuxtLinkLocale>
          </div>
          <div>
            <div>
              <!-- Button to switch to EN when the current locale is CS -->
              <nuxt-link
                v-if="locale === 'cs'"
                class="bg-transparent rounded-full px-4 py-2 font-normal nav-btns text-white text-base"
                :class="{ 'text-black': isDark, 'text-white': !isDark }"
                :to="switchLocalePath('en')"
              >
                EN
              </nuxt-link>
              <!-- Button to switch to CS when the current locale is EN -->
              <nuxt-link
                v-if="locale === 'en'"
                class="bg-transparent rounded-full px-4 py-2 font-normal nav-btns text-white text-base"
                :class="{ 'text-black': isDark, 'text-white': !isDark }"
                :to="switchLocalePath('cs')"
              >
                CZ
              </nuxt-link>
            </div>
          </div>
        </div>
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>

<style lang="scss" scoped>
.navbar-shadow {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  position: absolute;

  // @media screen {
  //   @media (max-width: 1024px) {
  //     background: none;
  //   }
  // }
}

.nav-btns {
  font-family: "Roboto";
  font-size: 0.875rem;
  font-weight: 400;
}

.mobile-btn {
  background-color: $color-primary;
  border-radius: 50%;
  width: 65px;
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  font-weight: 400;

  button {
    background-color: transparent;
  }
}

.mobile-menu {
  //color: $color-primary
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 20;

  a {
    color: #fff;
    text-align: center;
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
  }
}

.login-btn-icon {
  &:hover img {
    //background: red;
    filter: invert(100%);
  }
  &:hover img .login-icon {
    filter: invert(0);
  }
}

.navbar-links {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  font-weight: normal !important;
  //text-shadow: 0 0 0.01px;
  //letter-spacing: 0.235px;
  //margin: 0px 1px 0px 1px;
  //letter-spacing: .1em;
  &:hover {
    font-weight: bold !important;
    //text-shadow: 0 0 .9px black, 0 0 .9px black, 0 0 .9px black;
    //margin: 0;
  }
}

.navbar-links::after {
  content: attr(data-text);
  content: attr(data-text) / "";
  height: 0;
  visibility: hidden;
  overflow: hidden;
  user-select: none;
  pointer-events: none;

  font-weight: bold !important;

  @media speech {
    display: none;
  }
}

.navbar {
  transition: transform 0.3s;
  transform: translateY(0); /* Default position */
}

.navbar-hidden {
  transform: translateY(-100%);
}

//.navbar-hidden {
//    transform: translateY(0);
//}
</style>
