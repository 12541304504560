export const appPageTransition = {"name":"page","mode":"out-in"}

export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1, viewport-fit=cover"},{"name":"author","content":"Brainz Disruptive"},{"name":"format-detection","content":"telephone=no"},{"hid":"og:site_name","property":"og:site_name","content":"Opero | Propojujeme světy | Byznys hub a coworking v srdci Prahy"},{"hid":"twitter:domain","property":"twitter:domain","content":"opero.cz"}],"link":[{"rel":"apple-touch-icon","sizes":"180x180","href":"/apple-touch-icon.png"},{"rel":"icon","type":"image/png","sizes":"32x32","href":"/favicon-32x32.png"},{"rel":"icon","type":"image/png","sizes":"16x16","href":"/favicon-16x16.png"},{"rel":"manifest","href":"/site.webmanifest"},{"rel":"mask-icon","href":"/safari-pinned-tab.svg","color":"#5bbad5"}],"style":[],"script":[{"src":"https://cdnjs.cloudflare.com/ajax/libs/paper.js/0.12.17/paper-full.js","integrity":"sha512-z1Ws0aVF+gkSR/9Wo1d82UX7tOb80rPIa55NOTpptJr+EbyhfszWvw5nKMPn0il7T2AsNPgNjlvadGAgL/HOrA==","crossorigin":"anonymous","referrerpolicy":"no-referrer","type":"text/javascript"},{"id":"cookieyes","type":"text/javascript","src":"https://cdn-cookieyes.com/client_data/e1069752aabb7eeee11eb708/script.js"},{"innerHTML":"(function(c,l,a,r,i,t,y){\n  \t\t\t\t\t\tc[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};\n  \t\t\t\t\t\tt=l.createElement(r);t.async=1;t.src=\"https://www.clarity.ms/tag/\"+i;\n  \t\t\t\t\t\ty=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);\n  \t\t\t\t})(window, document, \"clarity\", \"script\", \"l49ltoq16n\");\n  \t\t\t\t"},{"src":"https://api.mapy.cz/loader.js","type":"text/javascript"}],"noscript":[],"title":"Opero | Propojujeme světy | Byznys hub a coworking v srdci Prahy"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const appManifest = true

export const remoteComponentIslands = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'