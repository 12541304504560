<script>
import { defineComponent } from "vue";
import gsap from "gsap";
export default defineComponent({
  name: "TestMask",
  props: {
    text: {
      type: String,
      required: true,
    },
    delay: {
      type: Number,
      default: 0,
    },
    width: {
      type: Number,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    bgColor: {
      type: String,
      default: "#fff",
    },
    bgTextColor: {
      type: String,
      default: "#1A1A1A",
    },
    enableSmall: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tl: null,
      small: false,
    };
  },
  computed: {
    ratio() {
      if (this.small) {
        if (this.enableSmall) {
          return 0.58;
        } else {
          return 0.83;
        }
      }
      return 1;
    },
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },
  mounted() {
    this.applyAnimations();
    window.addEventListener("resize", this.onResize);

    // gsap.set(".masker", { scaleX: 0, transformOrigin: "left center" });
    //
    // let screenWidth = document.body.clientWidth || window.innerWidth || document.documentElement.clientWidth;
    // if (screenWidth < 1024) {
    //     this.small = true
    // }
    //
    // setTimeout(() => this.tl = gsap
    //     .timeline({ paused: false, defaults: {
    //         // scrollTrigger: ".box",
    //
    //       }, scrollTrigger: {
    //         trigger: ".masker",
    //         // markers: true,
    //         start: "top center", // when the top of the trigger hits the top of the viewport
    //       } })
    //     .to(".masker", {
    //       scaleX: 1,
    //       ease: "power2.in",
    //       duration: 1.2,
    //       delay: this.delay,
    //     }), 200 + this.delay*1000)
  },

  methods: {
    applyAnimations() {
      gsap.set(`.masker${this.id}`, {
        scaleX: 0,
        transformOrigin: "left center",
      });

      const screenWidth =
        document.body.clientWidth ||
        window.innerWidth ||
        document.documentElement.clientWidth;
      if (screenWidth < 1024) {
        this.small = true;
      } else {
        this.small = false;
      }

      this.tl = gsap
        .timeline({ paused: false, defaults: {} })
        .to(`.masker${this.id}`, {
          scaleX: 1,
          ease: "power2.in",
          duration: 0.8,
          delay: this.delay,
          // scrollTrigger: {
          //     trigger: `.masker${this.id}`,
          //     // markers: true,
          //     // start: "top center", // when the top of the trigger hits the top of the viewport
          // }
        });

      // this.tl = gsap
      //     .timeline({ paused: false, defaults: {} })
      //     .to(".masker", {
      //             scaleX: 1,
      //             // ease: "power2.in",
      //             duration: 1.2,
      //             delay: this.delay,
      //             scrollTrigger: {
      //                 trigger: ".masker",
      //                 // markers: true,
      //                 start: "top center", // when the top of the trigger hits the top of the viewport
      //             }})
    },
    onResize() {
      this.applyAnimations();
    },
  },
});
</script>

<template>
  <svg
    :id="id"
    xmlns="http://www.w3.org/2000/svg"
    :width="width * ratio"
    :height="80 * ratio"
  >
    <defs>
      <clipPath id="theClipPath">
        <rect
          :class="'masker' + id"
          x="0"
          :y="10"
          :width="width * ratio * 2"
          :height="60 * ratio"
          fill="#444"
        />
      </clipPath>
    </defs>
    <rect
      x="0"
      :y="10 * ratio"
      :width="width * ratio"
      :height="80 * ratio"
      :fill="bgColor"
    />
    <text
      text-anchor="middle"
      :x="Math.floor((width * ratio) / 2)"
      :y="60 * ratio"
      :fill="bgTextColor"
    >
      {{ text }}
    </text>
    <rect
      :class="'masker' + id"
      x="0"
      :y="10 * ratio"
      :width="width * ratio * 2"
      :height="70 * ratio"
      fill="#1A1A1A"
    />
    <g clip-path="url(#theClipPath)">
      <text
        text-anchor="middle"
        :x="Math.floor((width * ratio) / 2)"
        :y="56 * ratio"
        fill="white"
      >
        {{ text }}
      </text>
    </g>
  </svg>
</template>
