import { default as aboutu8Sb32ihwPMeta } from "/app/pages/about.vue?macro=true";
import { default as _91id_93iiecAgtYGQMeta } from "/app/pages/article/[id].vue?macro=true";
import { default as articlesNoJuYiawbIMeta } from "/app/pages/articles.vue?macro=true";
import { default as calendly_meeting_confirmPyLinCZUi3Meta } from "/app/pages/calendly_meeting_confirm.vue?macro=true";
import { default as contact_form_confirmox1GSsfKMcMeta } from "/app/pages/contact_form_confirm.vue?macro=true";
import { default as contactV6oPJjs9RyMeta } from "/app/pages/contact.vue?macro=true";
import { default as event_registration_confirmucsWhdvlPLMeta } from "/app/pages/event_registration_confirm.vue?macro=true";
import { default as _91id_931Egj5puwfvMeta } from "/app/pages/event/[id].vue?macro=true";
import { default as archive3ZkjuegaugMeta } from "/app/pages/event/archive.vue?macro=true";
import { default as events_oldMocJKsjODDMeta } from "/app/pages/events_old.vue?macro=true";
import { default as eventsk5yx3AVVmZMeta } from "/app/pages/events.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as membershipWWFsX1R3saMeta } from "/app/pages/membership.vue?macro=true";
import { default as newsletter_subscriptionOfflPKjKXIMeta } from "/app/pages/newsletter_subscription.vue?macro=true";
import { default as projectsbEHPxy5SMHMeta } from "/app/pages/projects.vue?macro=true";
import { default as _91id_93cd8RZae0RFMeta } from "/app/pages/space/[id].vue?macro=true";
import { default as spacesMshZD7N4TDMeta } from "/app/pages/spaces.vue?macro=true";
export default [
  {
    name: aboutu8Sb32ihwPMeta?.name ?? "about___en",
    path: aboutu8Sb32ihwPMeta?.path ?? "/en/about",
    meta: aboutu8Sb32ihwPMeta || {},
    alias: aboutu8Sb32ihwPMeta?.alias || [],
    redirect: aboutu8Sb32ihwPMeta?.redirect || undefined,
    component: () => import("/app/pages/about.vue").then(m => m.default || m)
  },
  {
    name: aboutu8Sb32ihwPMeta?.name ?? "about___cs",
    path: aboutu8Sb32ihwPMeta?.path ?? "/cs/o-nas",
    meta: aboutu8Sb32ihwPMeta || {},
    alias: aboutu8Sb32ihwPMeta?.alias || [],
    redirect: aboutu8Sb32ihwPMeta?.redirect || undefined,
    component: () => import("/app/pages/about.vue").then(m => m.default || m)
  },
  {
    name: _91id_93iiecAgtYGQMeta?.name ?? "article-id___en",
    path: _91id_93iiecAgtYGQMeta?.path ?? "/en/blog/:id",
    meta: _91id_93iiecAgtYGQMeta || {},
    alias: _91id_93iiecAgtYGQMeta?.alias || [],
    redirect: _91id_93iiecAgtYGQMeta?.redirect || undefined,
    component: () => import("/app/pages/article/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93iiecAgtYGQMeta?.name ?? "article-id___cs",
    path: _91id_93iiecAgtYGQMeta?.path ?? "/cs/blog/:id",
    meta: _91id_93iiecAgtYGQMeta || {},
    alias: _91id_93iiecAgtYGQMeta?.alias || [],
    redirect: _91id_93iiecAgtYGQMeta?.redirect || undefined,
    component: () => import("/app/pages/article/[id].vue").then(m => m.default || m)
  },
  {
    name: articlesNoJuYiawbIMeta?.name ?? "articles___en",
    path: articlesNoJuYiawbIMeta?.path ?? "/en/blog",
    meta: articlesNoJuYiawbIMeta || {},
    alias: articlesNoJuYiawbIMeta?.alias || [],
    redirect: articlesNoJuYiawbIMeta?.redirect || undefined,
    component: () => import("/app/pages/articles.vue").then(m => m.default || m)
  },
  {
    name: articlesNoJuYiawbIMeta?.name ?? "articles___cs",
    path: articlesNoJuYiawbIMeta?.path ?? "/cs/blog",
    meta: articlesNoJuYiawbIMeta || {},
    alias: articlesNoJuYiawbIMeta?.alias || [],
    redirect: articlesNoJuYiawbIMeta?.redirect || undefined,
    component: () => import("/app/pages/articles.vue").then(m => m.default || m)
  },
  {
    name: calendly_meeting_confirmPyLinCZUi3Meta?.name ?? "calendly_meeting_confirm___en",
    path: calendly_meeting_confirmPyLinCZUi3Meta?.path ?? "/en/calendly-meeting-confirm",
    meta: calendly_meeting_confirmPyLinCZUi3Meta || {},
    alias: calendly_meeting_confirmPyLinCZUi3Meta?.alias || [],
    redirect: calendly_meeting_confirmPyLinCZUi3Meta?.redirect || undefined,
    component: () => import("/app/pages/calendly_meeting_confirm.vue").then(m => m.default || m)
  },
  {
    name: calendly_meeting_confirmPyLinCZUi3Meta?.name ?? "calendly_meeting_confirm___cs",
    path: calendly_meeting_confirmPyLinCZUi3Meta?.path ?? "/cs/calendly-meeting-confirm",
    meta: calendly_meeting_confirmPyLinCZUi3Meta || {},
    alias: calendly_meeting_confirmPyLinCZUi3Meta?.alias || [],
    redirect: calendly_meeting_confirmPyLinCZUi3Meta?.redirect || undefined,
    component: () => import("/app/pages/calendly_meeting_confirm.vue").then(m => m.default || m)
  },
  {
    name: contact_form_confirmox1GSsfKMcMeta?.name ?? "contact_form_confirm___en",
    path: contact_form_confirmox1GSsfKMcMeta?.path ?? "/en/contact-form-confirm",
    meta: contact_form_confirmox1GSsfKMcMeta || {},
    alias: contact_form_confirmox1GSsfKMcMeta?.alias || [],
    redirect: contact_form_confirmox1GSsfKMcMeta?.redirect || undefined,
    component: () => import("/app/pages/contact_form_confirm.vue").then(m => m.default || m)
  },
  {
    name: contact_form_confirmox1GSsfKMcMeta?.name ?? "contact_form_confirm___cs",
    path: contact_form_confirmox1GSsfKMcMeta?.path ?? "/cs/contact-form-confirm",
    meta: contact_form_confirmox1GSsfKMcMeta || {},
    alias: contact_form_confirmox1GSsfKMcMeta?.alias || [],
    redirect: contact_form_confirmox1GSsfKMcMeta?.redirect || undefined,
    component: () => import("/app/pages/contact_form_confirm.vue").then(m => m.default || m)
  },
  {
    name: contactV6oPJjs9RyMeta?.name ?? "contact___en",
    path: contactV6oPJjs9RyMeta?.path ?? "/en/contact",
    meta: contactV6oPJjs9RyMeta || {},
    alias: contactV6oPJjs9RyMeta?.alias || [],
    redirect: contactV6oPJjs9RyMeta?.redirect || undefined,
    component: () => import("/app/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactV6oPJjs9RyMeta?.name ?? "contact___cs",
    path: contactV6oPJjs9RyMeta?.path ?? "/cs/kontakt",
    meta: contactV6oPJjs9RyMeta || {},
    alias: contactV6oPJjs9RyMeta?.alias || [],
    redirect: contactV6oPJjs9RyMeta?.redirect || undefined,
    component: () => import("/app/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: event_registration_confirmucsWhdvlPLMeta?.name ?? "event_registration_confirm___en",
    path: event_registration_confirmucsWhdvlPLMeta?.path ?? "/en/registration-confirm",
    meta: event_registration_confirmucsWhdvlPLMeta || {},
    alias: event_registration_confirmucsWhdvlPLMeta?.alias || [],
    redirect: event_registration_confirmucsWhdvlPLMeta?.redirect || undefined,
    component: () => import("/app/pages/event_registration_confirm.vue").then(m => m.default || m)
  },
  {
    name: event_registration_confirmucsWhdvlPLMeta?.name ?? "event_registration_confirm___cs",
    path: event_registration_confirmucsWhdvlPLMeta?.path ?? "/cs/registration-confirm",
    meta: event_registration_confirmucsWhdvlPLMeta || {},
    alias: event_registration_confirmucsWhdvlPLMeta?.alias || [],
    redirect: event_registration_confirmucsWhdvlPLMeta?.redirect || undefined,
    component: () => import("/app/pages/event_registration_confirm.vue").then(m => m.default || m)
  },
  {
    name: _91id_931Egj5puwfvMeta?.name ?? "event-id___en",
    path: _91id_931Egj5puwfvMeta?.path ?? "/en/events/:id",
    meta: _91id_931Egj5puwfvMeta || {},
    alias: _91id_931Egj5puwfvMeta?.alias || [],
    redirect: _91id_931Egj5puwfvMeta?.redirect || undefined,
    component: () => import("/app/pages/event/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_931Egj5puwfvMeta?.name ?? "event-id___cs",
    path: _91id_931Egj5puwfvMeta?.path ?? "/cs/akce/:id",
    meta: _91id_931Egj5puwfvMeta || {},
    alias: _91id_931Egj5puwfvMeta?.alias || [],
    redirect: _91id_931Egj5puwfvMeta?.redirect || undefined,
    component: () => import("/app/pages/event/[id].vue").then(m => m.default || m)
  },
  {
    name: archive3ZkjuegaugMeta?.name ?? "event-archive___en",
    path: archive3ZkjuegaugMeta?.path ?? "/en/events/archive",
    meta: archive3ZkjuegaugMeta || {},
    alias: archive3ZkjuegaugMeta?.alias || [],
    redirect: archive3ZkjuegaugMeta?.redirect || undefined,
    component: () => import("/app/pages/event/archive.vue").then(m => m.default || m)
  },
  {
    name: archive3ZkjuegaugMeta?.name ?? "event-archive___cs",
    path: archive3ZkjuegaugMeta?.path ?? "/cs/akce/archiv",
    meta: archive3ZkjuegaugMeta || {},
    alias: archive3ZkjuegaugMeta?.alias || [],
    redirect: archive3ZkjuegaugMeta?.redirect || undefined,
    component: () => import("/app/pages/event/archive.vue").then(m => m.default || m)
  },
  {
    name: events_oldMocJKsjODDMeta?.name ?? "events_old___en",
    path: events_oldMocJKsjODDMeta?.path ?? "/en/events_old",
    meta: events_oldMocJKsjODDMeta || {},
    alias: events_oldMocJKsjODDMeta?.alias || [],
    redirect: events_oldMocJKsjODDMeta?.redirect || undefined,
    component: () => import("/app/pages/events_old.vue").then(m => m.default || m)
  },
  {
    name: events_oldMocJKsjODDMeta?.name ?? "events_old___cs",
    path: events_oldMocJKsjODDMeta?.path ?? "/cs/events_old",
    meta: events_oldMocJKsjODDMeta || {},
    alias: events_oldMocJKsjODDMeta?.alias || [],
    redirect: events_oldMocJKsjODDMeta?.redirect || undefined,
    component: () => import("/app/pages/events_old.vue").then(m => m.default || m)
  },
  {
    name: eventsk5yx3AVVmZMeta?.name ?? "events___en",
    path: eventsk5yx3AVVmZMeta?.path ?? "/en/events",
    meta: eventsk5yx3AVVmZMeta || {},
    alias: eventsk5yx3AVVmZMeta?.alias || [],
    redirect: eventsk5yx3AVVmZMeta?.redirect || undefined,
    component: () => import("/app/pages/events.vue").then(m => m.default || m)
  },
  {
    name: eventsk5yx3AVVmZMeta?.name ?? "events___cs",
    path: eventsk5yx3AVVmZMeta?.path ?? "/cs/akce",
    meta: eventsk5yx3AVVmZMeta || {},
    alias: eventsk5yx3AVVmZMeta?.alias || [],
    redirect: eventsk5yx3AVVmZMeta?.redirect || undefined,
    component: () => import("/app/pages/events.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index___en",
    path: indexN6pT4Un8hYMeta?.path ?? "/en",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect || undefined,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index___cs",
    path: indexN6pT4Un8hYMeta?.path ?? "/cs",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect || undefined,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: membershipWWFsX1R3saMeta?.name ?? "membership___en",
    path: membershipWWFsX1R3saMeta?.path ?? "/en/membership",
    meta: membershipWWFsX1R3saMeta || {},
    alias: membershipWWFsX1R3saMeta?.alias || [],
    redirect: membershipWWFsX1R3saMeta?.redirect || undefined,
    component: () => import("/app/pages/membership.vue").then(m => m.default || m)
  },
  {
    name: membershipWWFsX1R3saMeta?.name ?? "membership___cs",
    path: membershipWWFsX1R3saMeta?.path ?? "/cs/clenstvi",
    meta: membershipWWFsX1R3saMeta || {},
    alias: membershipWWFsX1R3saMeta?.alias || [],
    redirect: membershipWWFsX1R3saMeta?.redirect || undefined,
    component: () => import("/app/pages/membership.vue").then(m => m.default || m)
  },
  {
    name: newsletter_subscriptionOfflPKjKXIMeta?.name ?? "newsletter_subscription___en",
    path: newsletter_subscriptionOfflPKjKXIMeta?.path ?? "/en/newsletter-subscription",
    meta: newsletter_subscriptionOfflPKjKXIMeta || {},
    alias: newsletter_subscriptionOfflPKjKXIMeta?.alias || [],
    redirect: newsletter_subscriptionOfflPKjKXIMeta?.redirect || undefined,
    component: () => import("/app/pages/newsletter_subscription.vue").then(m => m.default || m)
  },
  {
    name: newsletter_subscriptionOfflPKjKXIMeta?.name ?? "newsletter_subscription___cs",
    path: newsletter_subscriptionOfflPKjKXIMeta?.path ?? "/cs/newsletter-subscription",
    meta: newsletter_subscriptionOfflPKjKXIMeta || {},
    alias: newsletter_subscriptionOfflPKjKXIMeta?.alias || [],
    redirect: newsletter_subscriptionOfflPKjKXIMeta?.redirect || undefined,
    component: () => import("/app/pages/newsletter_subscription.vue").then(m => m.default || m)
  },
  {
    name: projectsbEHPxy5SMHMeta?.name ?? "projects___en",
    path: projectsbEHPxy5SMHMeta?.path ?? "/en/projects",
    meta: projectsbEHPxy5SMHMeta || {},
    alias: projectsbEHPxy5SMHMeta?.alias || [],
    redirect: projectsbEHPxy5SMHMeta?.redirect || undefined,
    component: () => import("/app/pages/projects.vue").then(m => m.default || m)
  },
  {
    name: projectsbEHPxy5SMHMeta?.name ?? "projects___cs",
    path: projectsbEHPxy5SMHMeta?.path ?? "/cs/projekty",
    meta: projectsbEHPxy5SMHMeta || {},
    alias: projectsbEHPxy5SMHMeta?.alias || [],
    redirect: projectsbEHPxy5SMHMeta?.redirect || undefined,
    component: () => import("/app/pages/projects.vue").then(m => m.default || m)
  },
  {
    name: _91id_93cd8RZae0RFMeta?.name ?? "space-id___en",
    path: _91id_93cd8RZae0RFMeta?.path ?? "/en/space/:id",
    meta: _91id_93cd8RZae0RFMeta || {},
    alias: _91id_93cd8RZae0RFMeta?.alias || [],
    redirect: _91id_93cd8RZae0RFMeta?.redirect || undefined,
    component: () => import("/app/pages/space/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93cd8RZae0RFMeta?.name ?? "space-id___cs",
    path: _91id_93cd8RZae0RFMeta?.path ?? "/cs/prostor/:id",
    meta: _91id_93cd8RZae0RFMeta || {},
    alias: _91id_93cd8RZae0RFMeta?.alias || [],
    redirect: _91id_93cd8RZae0RFMeta?.redirect || undefined,
    component: () => import("/app/pages/space/[id].vue").then(m => m.default || m)
  },
  {
    name: spacesMshZD7N4TDMeta?.name ?? "spaces___en",
    path: spacesMshZD7N4TDMeta?.path ?? "/en/space",
    meta: spacesMshZD7N4TDMeta || {},
    alias: spacesMshZD7N4TDMeta?.alias || [],
    redirect: spacesMshZD7N4TDMeta?.redirect || undefined,
    component: () => import("/app/pages/spaces.vue").then(m => m.default || m)
  },
  {
    name: spacesMshZD7N4TDMeta?.name ?? "spaces___cs",
    path: spacesMshZD7N4TDMeta?.path ?? "/cs/prostor",
    meta: spacesMshZD7N4TDMeta || {},
    alias: spacesMshZD7N4TDMeta?.alias || [],
    redirect: spacesMshZD7N4TDMeta?.redirect || undefined,
    component: () => import("/app/pages/spaces.vue").then(m => m.default || m)
  }
]