
// @ts-nocheck
import locale__app_locales_en_js from "../locales/en.js?hash=bde023b2&locale=en";
import locale__app_locales_cs_js from "../locales/cs.js?hash=86883749&locale=cs";


export const localeCodes =  [
  "en",
  "cs"
]

export const localeMessages = {
  "en": [{ key: "../locales/en.js", load: () => Promise.resolve(locale__app_locales_en_js), cache: false }],
  "cs": [{ key: "../locales/cs.js", load: () => Promise.resolve(locale__app_locales_cs_js), cache: false }]
}

export const vueI18nConfigs = [
  
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": ""
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "en",
      "files": [
        "locales/en.js"
      ]
    },
    {
      "code": "cs",
      "files": [
        "locales/cs.js"
      ]
    }
  ],
  "defaultLocale": "cs",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix",
  "lazy": false,
  "langDir": "locales/",
  "rootRedirect": null,
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "config",
  "pages": {
    "index": {
      "cs": "/",
      "en": "/"
    },
    "membership": {
      "cs": "/clenstvi",
      "en": "/membership"
    },
    "spaces": {
      "cs": "/prostor",
      "en": "/space"
    },
    "space/[id]": {
      "cs": "/prostor/[id]",
      "en": "/space/[id]"
    },
    "projects": {
      "cs": "/projekty",
      "en": "/projects"
    },
    "events": {
      "cs": "/akce",
      "en": "/events"
    },
    "event/[id]": {
      "cs": "/akce/[id]",
      "en": "/events/[id]"
    },
    "event/archive": {
      "cs": "/akce/archiv",
      "en": "/events/archive"
    },
    "about": {
      "cs": "/o-nas",
      "en": "/about"
    },
    "articles": {
      "cs": "/blog",
      "en": "/blog"
    },
    "article/[id]": {
      "cs": "/blog/[id]",
      "en": "/blog/[id]"
    },
    "contact": {
      "cs": "/kontakt",
      "en": "/contact"
    },
    "newsletter_subscription": {
      "cs": "/newsletter-subscription",
      "en": "/newsletter-subscription"
    },
    "calendly_meeting_confirm": {
      "cs": "/calendly-meeting-confirm",
      "en": "/calendly-meeting-confirm"
    },
    "contact_form_confirm": {
      "cs": "/contact-form-confirm",
      "en": "/contact-form-confirm"
    },
    "event_registration_confirm": {
      "cs": "/registration-confirm",
      "en": "/registration-confirm"
    }
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const nuxtI18nOptionsDefault = {
  "experimental": {
    "localeDetector": ""
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [],
  "defaultLocale": "",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": null,
  "rootRedirect": null,
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false
}

export const nuxtI18nInternalOptions = {
  "__normalizedLocales": [
    {
      "code": "en",
      "files": [
        {
          "path": "locales/en.js"
        }
      ]
    },
    {
      "code": "cs",
      "files": [
        {
          "path": "locales/cs.js"
        }
      ]
    }
  ]
}

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

