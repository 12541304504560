// dodaný script na trackování leadů

export default defineNuxtPlugin(() => {
  if (process.client) {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.src = "https://" + "ct.l" + "eady.com/IXk89LzYX0Qvx1Ps/L" + ".js";
    document.getElementsByTagName("head")[0].appendChild(script);
  }
});
