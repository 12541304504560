<script>
import gsap from "gsap";

export default {
  name: "Error",
  props: {
    error: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      width: 1365,
    };
  },
  mounted() {
    this.width =
      document?.body?.clientWidth ||
      window?.innerWidth ||
      document?.documentElement?.clientWidth;
    console.log("error", this.error);
    this.animateFall();
  },
  handleResize() {
    this.width =
      document?.body?.clientWidth ||
      window?.innerWidth ||
      document?.documentElement?.clientWidth;
  },
  methods: {
    animateFall() {
      gsap.set("#err1", { bottom: 400, right: 100 });
      gsap.set("#err2", { bottom: 600, right: -115 });
      gsap.set("#err3", { bottom: 300, right: -15 });
      gsap.set("#err4", { bottom: 300, right: 270 });
      gsap.set("#err5", { bottom: 600, right: 500 });
      gsap.set("#err6", { bottom: 400, right: 450 });
      gsap.to("#err1", {
        duration: 0.7,
        bottom: 0,
        right: 100,
        delay: 0.5,
        ease: "Bounce.easeOut(0.1, 1.9)",
      });
      gsap.to("#err2", {
        duration: 0.7,
        bottom: 65,
        right: -110,
        rotation: 30,
        delay: 2.1,
        ease: "Bounce.easeOut(0.1, 1.9)",
      });
      gsap.to("#err3", {
        duration: 0.7,
        bottom: 325,
        rotation: 80,
        transformOrigin: "left 50%",
        delay: 1.9,
        ease: "Bounce.easeOut(0.1, 1.9)",
      });
      gsap.to("#err4", {
        duration: 0.7,
        bottom: -5,
        right: 240,
        rotation: -40,
        transformOrigin: "left 50%",
        delay: 1.1,
        ease: "Bounce.easeOut(0.1, 1.9)",
      });
      gsap.to("#err5", {
        duration: 0.7,
        bottom: 0,
        right: 527,
        rotation: -20,
        transformOrigin: "left 50%",
        delay: 1.4,
        ease: "Bounce.easeOut(0.1, 1.9)",
      });
      gsap.to("#err6", {
        duration: 0.7,
        bottom: 200,
        right: 420,
        rotation: 5,
        transformOrigin: "left 50%",
        delay: 1.7,
        ease: "Bounce.easeOut(0.1, 1.9)",
      });
    },
  },
};
</script>

<template>
  <div class="overflow-hidden bg-primary min-h-screen">
    <div class="navbar-wrapper">
      <Navbar :is-dark="true" :is-white="true" />
    </div>
    <div class="lg:relative">
      <div class="w-full pt-28 lg:pt-32">
        <div class="flex justify-start lg:justify-center">
          <div class="max-w-7xl relative overflow-hidden">
            <div class="pb-12 lg:pb-24 px-0 sm:px-6">
              <h1 class="h1">
                <TestMask
                  id="404"
                  :text="`Error ${error?.statusCode}`"
                  :width="$i18n.locale === 'cs' ? 250 : 250"
                  :delay="1000"
                  bg-color="black"
                  bg-text-color="white"
                />
              </h1>
              <div class="pt-6">
                <div v-if="error?.statusCode === 404">
                  <div
                    class="error-title pb-6 bg-opaci w-fit"
                    v-html="$t('error.404.title')"
                  ></div>
                  <p
                    class="bg-opaci w-fit"
                    v-html="$t('error.404.subtitle')"
                  ></p>
                </div>
                <div v-if="error?.statusCode === 500">
                  <div
                    class="error-title pb-6 bg-opaci w-fit"
                    v-html="$t('error.500.title')"
                  ></div>
                  <p
                    class="bg-opaci w-fit"
                    v-html="$t('error.500.subtitle')"
                  ></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-center">
      <div class="w-1/2 errors-pile-container">
        <div v-for="i in 6" :id="'err' + i" :key="i" class="errorText">
          Error {{ error?.statusCode }}
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.errorContainer {
  height: 100vh;
  width: 100vw;
  background: #ffc800;
}
.errorText {
  position: absolute;
  y: -350px;
  background: #1a1a1a;
  color: #fff;
  //opacity: 0.6;
  font-family: Roboto;
  font-size: 61.765px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  padding: 12px;
  white-space: nowrap;
  //mix-blend-mode: overlay;
}
.bg-opaci {
  background: rgba(255, 200, 0, 0.7);
}
.errors-pile-container {
  position: relative;
  height: 320px;
}
.error-title {
  color: #1a1a1a;
  font-family: Roboto;
  font-size: 50px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
</style>
