<template>
  <div>
    <!-- <FullScreeenLoader v-if="!sessionStorage?.getItem('loading')" /> -->
    <NuxtLoadingIndicator />
    <NuxtPage />
  </div>
</template>

<style>
.page-enter-active,
.page-leave-active {
  transition: all 0.2s;
}
.page-enter-from,
.page-leave-to {
  opacity: 0;
  filter: blur(1rem);
}
</style>
